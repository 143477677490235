export const translations = {
  codigoSap: {
    pt: "Código SAP e Endereços",
    en: "SAP Code and Addresses",
    es: "Código SAP",
    de: "SAP-Code",
  },
  codigo: {
    pt: "Código",
    en: "Code",
    es: "Código",
    de: "Code",
  },
  cep: {
    pt: "Cep",
    en: "Zip code",
    de: "Postleitzahl",
    es: "Código postal",
  },
  postalCode: {
    pt: "Código postal",
    en: "Zip code",
    de: "Postleitzahl",
    es: "Código postal",
  },
  uf: {
    pt: "UF",
    en: "Federative unit",
    de: "Bundesstaat",
    es: 'Estado/Provincia',
  },
  provincy: {
    pt: "Província",
    en: "State",
    de: "Länder",
    es: "Província",
  },
  lougradouro: {
    pt: "Logradouro",
    en: "Public place",
    de: "Adresse",
    es: 'Direccion',
  },
  numero: {
    pt: "Número",
    en: "Number",
    de: "Hausnummer",
    es: "Número",
  },
  bairro: {
    pt: "Bairro",
    en: "Neighborhood",
    de: "Stadtviertel",
    es: "Barrio",
  },
  cidade: {
    pt: "Cidade",
    en: "City",
    de: "Ort",
    es: "Ciudad",
  },
  descricao: {
    pt: "Adicione abaixo os endereços e os códigos SAP referente a esse cliente.",
    en: "Add the addresses and SAP codes below for this client.",
    es: "Añada abajo las direcciones y los códigos SAP correspondientes a este cliente.",
    de: "Fügen Sie unten die SAP-Codes für diesen Kunden hinzu.",
  },
  adicionar: {
    pt: "Adicionar",
    en: "Add",
    es: "Agregar",
    de: "Hinzufügen",
  },
};
