export const translations = {
  alertas: {
    pt: "Alertas",
    en: "Alerts",
    de: "Benachrichtigungen",
    es: "Alertas",
  },
  historicoDeAlertas: {
    pt: "Historico de Alertas",
    en: "Alert History",
    de: "Benachrichtigungsverlauf",
    es: "Historial de alertas",
  },
  centro: {
    pt: "Centro",
    en: "Center",
    de: "Werk",
    es: "Centro",
  },
  segmentacao: {
    pt: "Segmentação de Clientes",
    en: "Customer Segmentation",
    de: "Kundensegmentierung",
    es: "Segmentación de Clientes",
  },
  clientes: {
    pt: "Clientes",
    en: "Customers",
    de: "Kunden",
    es: "Clientes",
  },
  produtos: {
    pt: "Produtos",
    en: "Product",
    de: "Produkte",
    es: "Producto",
  },
  tiposDeProduto: {
    pt: "Tipos de Produto",
    en: "Product types",
    de: "Produkttypen",
    es: "Tipos de Productos",
  },
  unidadeDeNegocio: {
    pt: "Unidade de Negócio",
    en: "Business Unit",
    de: "Geschäftseinheit",
    es: "Unidades de Negocios",
  },
  linhaDeNegocio: {
    pt: "Linha de Negócio",
    en: "Line of Business",
    de: "Geschäftslinie",
    es: "Línea de negocio",
  },
  transportadora: {
    pt: "Transportadora",
    en: "Carrier",
    de: "Spediteur",
    es: "Transportador",
  },
  tiposDepallet: {
    pt: "Tipos de Paletes",
    en: "Pallet types",
    de: "Palettentypen",
    es: "Tipos de Palets",
  },
  usuarios: {
    pt: "Usuários",
    en: "Users",
    de: "Benutzer",
    es: "Usuários",
  },
  veiculos: {
    pt: "Veículos",
    en: "Vehicles",
    de: "Fahrzeuge",
    es: "Vehículos",
  },
  tiposDeVeiculos: {
    pt: "Tipos de Veículos",
    en: "Vehicles Types",
    de: "Fahrzeugtypen",
    es: "Tipos de vehículos",
  },
  categoriaDocs: {
    pt: "Categoria de documentos",
    en: "Dpcuments Category",
    de: "Fahrzeuge",
    es: "Vehículos",
  },
  perfilUsuario: {
    pt: "Perfil de Usuário",
    en: "User Profile",
    de: "Benutzerprofil",
    es: "Perfil de Usuario",
  },
  permissoes: {
    pt: "Permissões",
    en: "Permissions",
    de: "Berechtigungen",
    es: "Permisos",
  },
  relatoriosGerenciais: {
    pt: "Relatórios Gerenciais",
    en: "Management Reports",
    de: "Managementberichte",
    es: "Informes de Gestión",
  },
  zipcodeInternacional: {
    pt: "Zipcode Internacional",
    en: "International Zipcode",
    de: "Internationale Postleitzahl",
    es: "Código Postal Internacional",
  },
  tempoTransitoEstrangeiro: {
    pt: "Tempo de trânsito (estrangeiro)",
    en: "Transit Time (Foreign)",
    de: "Transitzeit (Ausland)",
    es: "Tiempo de tránsito (extranjero)",
  },
};
