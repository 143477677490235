import React, {
  useEffect,
  useCallback,
  useState,
  ForwardRefExoticComponent,
  RefAttributes,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import * as Yup from "yup";
import { FormHandles, Scope } from "@unform/core";
import {
  FetchClientCodeActions,
  FetchClientCodeState,
  DeleteClientCodeActions,
  DeleteClientCodeState,
  CreateClientCodeActions,
  CreateClientCodeState,
} from "store/ducks/settings/client-codes";
import { Modal, Alert } from "components/shared";
import { Input, InputMask, Select } from "components/shared/Form";
import { useTranslation, useValidation } from "hooks";
import { translations } from "./translations";
import * as S from "./styles";
import { ListCitiesActions, ListCitiesState } from "store/ducks/cities";
import { CepActions, CepState } from "store/ducks/cep";
import { statesOptions } from "utils/data/states";
import { ICitiesOptions } from "interfaces/city";
import { EditModal } from "./EditModal";
import { ClientCode } from "interfaces/client-codes";

interface Props {
  clientId?: string;
  exterior: boolean;
  formRef: React.RefObject<FormHandles>;
}

export interface ClientCodeRef {
  getData: () => ClientCode[];
}

interface IClientCode
  extends ForwardRefExoticComponent<Props & RefAttributes<ClientCodeRef>> {}

export const ClientCodes: IClientCode = forwardRef<ClientCodeRef, Props>(
  ({ clientId, exterior, formRef }, ref) => {
    const dispatch = useDispatch();
    const alertRef = useRef<any>(null);
    const { getTranslation } = useTranslation(translations);
    const { handleFormErrors } = useValidation();
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [clientCodes, setClientCodes] = useState<ClientCode[]>([]);

    const { data: dataCities, loading: loadingCities } = useSelector<
      RootStateOrAny,
      ListCitiesState
    >((state) => state.listCities);

    const { loading: loadingCep } = useSelector<RootStateOrAny, CepState>(
      (state) => state.cep
    );

    const { data, loading } = useSelector<RootStateOrAny, FetchClientCodeState>(
      (state) => state.fetchClientCode
    );
    const { loading: deleteLoading } = useSelector<
      RootStateOrAny,
      DeleteClientCodeState
    >((state) => state.deleteClientCode);

    const { loading: createLoading } = useSelector<
      RootStateOrAny,
      CreateClientCodeState
    >((state) => state.createClientCode);

    const fetchClientCodes = useCallback(() => {
      dispatch(FetchClientCodeActions.request(clientId));
    }, [clientId, dispatch]);

    const getListCities = useCallback(
      (option) => {
        dispatch(ListCitiesActions.request({ state: option.value }));
      },
      [dispatch]
    );
    const setIbge = useCallback((ibge) => {
      if (formRef.current)
        return formRef.current.setFieldValue(
          "clientCodes.address_id_city_ibge",
          ibge
        );
    }, []);

    const putDataInFields = useCallback(
      (data) => {
        if (!data && formRef.current) {
          return formRef.current.setFieldError(
            "clientCodes.address_zipcode",
            getTranslation("CEPnaoEncontrado")
          );
        }

        const stateOption = statesOptions.filter(
          (stateOption) => stateOption.value === data.address_state
        );

        const setCityForm = (cities: ICitiesOptions[]) => {
          const cityOption = cities.filter(
            (city) => city.ibge === data.address_id_city_ibge
          );

          if (cityOption.length > 0) {
            setIbge(cityOption[0].ibge);

            if (formRef.current) {
              formRef.current.setFieldValue(
                "clientCodes.address_city",
                cityOption[0]
              );
            }
          }
        };

        dispatch(
          ListCitiesActions.request({ state: data.address_state }, setCityForm)
        );

        if (stateOption.length > 0 && formRef.current) {
          formRef.current.setFieldValue(
            "clientCodes.address_street",
            data.address_street
          );
          formRef.current.setFieldValue(
            "clientCodes.address_neighborhood",
            data.address_neighborhood
          );
          formRef.current.setFieldValue(
            "clientCodes.address_state",
            stateOption[0]
          );
        }
      },
      [dispatch, getTranslation, setIbge]
    );

    const fetchCep = useCallback(
      (cep) => {
        if (!exterior) {
          formRef.current?.setFieldError("clientCodes.address_zipcode", "");
          const cleanCEP = cep ? cep.replace(/[^0-9]/g, "") : null;
          if (cleanCEP && cleanCEP.length < 8) {
            return formRef.current?.setFieldError(
              "clientCodes.address_zipcode",
              getTranslation("CEPInvalido")
            );
          }
          if (cleanCEP && cleanCEP.length === 8) {
            dispatch(CepActions.request(cleanCEP, putDataInFields));
          }
        }
      },
      [dispatch, getTranslation, putDataInFields, exterior]
    );

    const onSuccess = useCallback(() => {
      [
        "client_code",
        "address_street",
        "address_number",
        "address_neighborhood",
        "address_zipcode",
        "address_city",
        "address_state",
      ].forEach((field) => {
        formRef.current?.clearField(`clientCodes.${field}`);
      });

      fetchClientCodes();
      setModalOpen(false);
    }, [fetchClientCodes]);

    const handleDelete = useCallback(async (clientCodeId) => {
      if (clientId) {
        setModalOpen(true);

        await new Promise((resolve) => {
          alertRef.current = resolve;
        }).then(() => {
          setModalOpen(false);
          dispatch(DeleteClientCodeActions.request(clientCodeId, onSuccess));
        });
      } else {
        setClientCodes((prev) =>
          prev.filter(({ client_code }) => client_code !== clientCodeId)
        );
      }
    }, []);

    const onDelete = useCallback(() => {
      alertRef.current && alertRef.current(true);
    }, []);

    const handleAdd = useCallback(async () => {
      try {
        const dataForm = formRef?.current?.getData() as any;

        formRef?.current?.setErrors({});

        const schema = Yup.object().shape({
          clientCodes: Yup.object({
            client_code: Yup.string()
              .test("codeValidation", "Código inválido", (value: any) =>
                Number.isInteger(Math.abs(Number(value)))
              )
              .required("Obrigatório"),
            address_street: Yup.string().required("Obrigatório"),
            address_number: Yup.string().required("Obrigatório"),
            address_neighborhood: Yup.string().required("Obrigatório"),
            address_zipcode: Yup.string().required("Obrigatório"),
            address_city: Yup.string().required("Obrigatório"),
            address_state: Yup.string().required("Obrigatório"),
          }),
        });

        await schema.validate(dataForm, {
          abortEarly: false,
        });

        if (!clientId) {
          // check if it has already been added
          const repeated = clientCodes.find(
            (cc) => cc.client_code === dataForm.clientCodes.client_code
          );

          if (!repeated) {
            setClientCodes((prev) => [...prev, dataForm.clientCodes]);
          }
        } else {
          const { clientCodes } = dataForm;
          clientCodes.general_client_id = clientId;
          dispatch(CreateClientCodeActions.request(clientCodes, onSuccess));
        }
      } catch (error) {
        handleFormErrors(error, formRef);
      }
    }, [clientCodes, clientId, formRef, dispatch, handleFormErrors]);

    useImperativeHandle(
      ref,
      () => ({
        getData: () => clientCodes,
      }),
      [clientCodes]
    );

    useEffect(() => {
      clientId && fetchClientCodes();

      return () => {
        dispatch(FetchClientCodeActions.reset());
      };
    }, [fetchClientCodes]);

    return (
      <S.Container>
        <Modal isOpen={modalOpen}>
          <Alert
            title={`Remover Código SAP`}
            text="Ao excluir o código SAP do cliente, você estará impedindo a associação do mesmo na importação de Pickings. Deseja realmente remover?"
            close={() => setModalOpen(false)}
            action={onDelete}
            labelAction="Remover"
            isLoading={deleteLoading}
          />
        </Modal>
        <S.Title>
          {getTranslation("codigoSap")} {loading && <S.Loading />}
        </S.Title>
        <S.Text>{getTranslation("descricao")}</S.Text>
        <S.BoxContainer>
          <Scope path="clientCodes">
            <Input
              name="address_id_city_ibge"
              hidden
              containerStyle={{ position: "absolute" }}
            />
            <S.FormRow>
              <InputMask
                name="address_zipcode"
                label={
                  exterior
                    ? getTranslation("postalCode")
                    : getTranslation("cep")
                }
                onBlur={(e: any) => fetchCep(e.target.value)}
                isLoading={loadingCep}
                mask={exterior ? "********" : "99999-999"}
                // disabled={exterior}
              />
              <Input
                name="address_street"
                label={getTranslation("lougradouro")}
                // disabled={exterior}
              />
              <Input
                name="address_number"
                label={getTranslation("numero")}
                // disabled={exterior}
              />
              <Input
                name="address_neighborhood"
                label={getTranslation("bairro")}
                // disabled={exterior}
              />
            </S.FormRow>
            <S.FormRow>
              {exterior ? (
                <Input
                  name="address_state"
                  label={getTranslation("provincy")}
                  // disabled={exterior}
                />
              ) : (
                <Select
                  name="address_state"
                  label={getTranslation("uf")}
                  options={statesOptions}
                  disabled={exterior}
                  placeholder={getTranslation("selecione")}
                  onChange={(e) => getListCities(e)}
                />
              )}
              {exterior ? (
                <Input
                  name="address_city"
                  label={getTranslation("cidade")}
                  // disabled={exterior}
                />
              ) : (
                <Select
                  name="address_city"
                  label={getTranslation("cidade")}
                  isDisabled={exterior ? exterior : loadingCities}
                  isLoading={loadingCities}
                  options={dataCities}
                  placeholder={getTranslation("selecione")}
                  onChange={(e: any) => setIbge(e.ibge)}
                />
              )}
            </S.FormRow>
            <S.FormRow>
              <Input
                name="client_code"
                placeholder={getTranslation("codigoSap")}
              />
              <S.ButtonMini type="button" className="add" onClick={handleAdd}>
                {createLoading ? <S.Loading /> : getTranslation("adicionar")}
              </S.ButtonMini>
            </S.FormRow>
          </Scope>
          {clientCodes.concat(data).map((code: ClientCode) => (
            <S.Item key={code.id || code.client_code}>
              <S.ItemLabel>
                <span>{getTranslation("codigo")}: </span>
                {code.client_code}
              </S.ItemLabel>
              <EditModal
                codeData={code}
                isExterior={exterior}
                isNewClient={!Boolean(clientId)}
              />
              <S.DeleteButton
                onClick={() => handleDelete(code.id || code.client_code)}
                type="button"
              >
                <S.Trash size={22} />
              </S.DeleteButton>
            </S.Item>
          ))}
        </S.BoxContainer>
      </S.Container>
    );
  }
);
