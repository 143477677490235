import { Modal } from "components/shared";
import * as S from "./styles";
import { useTranslation, useValidation } from "hooks";
import { translations } from "./translations";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { Form } from "@unform/web";
import { SubmitHandler, FormHandles } from "@unform/core";
import { Input, InputMask, Select } from "components/shared/Form";
import { statesOptions } from "utils/data/states";
import { ICitiesOptions } from "interfaces/city";
import { CepActions, CepState } from "store/ducks/cep";
import * as Yup from "yup";
import {
  UpdateClientCodeActions,
  UpdateClientCodeState,
} from "store/ducks/settings/client-codes";
import { useCities } from "hooks/useCities";

interface IEditProps {
  codeData: any;
  isExterior: boolean;
  isNewClient?: boolean;
}

export const EditModal: React.FC<IEditProps> = ({
  codeData,
  isExterior,
  isNewClient,
}) => {
  const formEditRef = useRef<FormHandles>(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { getTranslation } = useTranslation(translations);
  const { handleFormErrors } = useValidation();
  const { state, setState, cities } = useCities(codeData.address_state);

  const { loading: updateLoading } = useSelector<
    RootStateOrAny,
    UpdateClientCodeState
  >((state) => state.updateClientCode);

  const onSuccess = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleSubmit: SubmitHandler = useCallback(
    async (data) => {
      try {
        formEditRef.current?.setErrors({});
        const schema = Yup.object().shape({
          address_street: Yup.string().required(
            getTranslation("campoObrigatorio")
          ),
          address_number: Yup.string().required(
            getTranslation("campoObrigatorio")
          ),
          address_neighborhood: Yup.string(),
          address_zipcode: Yup.string().required(
            getTranslation("campoObrigatorio")
          ),
          address_city: Yup.string().required(
            getTranslation("campoObrigatorio")
          ),
          address_state: Yup.string().required(
            getTranslation("campoObrigatorio")
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        if (isNewClient) {
          Object.entries(data).forEach(([key, value]) => {
            codeData[key] = value;
          });

          onSuccess()
        } else {
          dispatch(
            UpdateClientCodeActions.request(codeData.id, data, onSuccess)
          );
        }
      } catch (error) {
        handleFormErrors(error, formEditRef);
      }
    },
    [codeData.id, dispatch, getTranslation, handleFormErrors, onSuccess]
  );

  const getListCities = useCallback(
    (option) => {
      if (!isExterior && option !== undefined) {
        setState((prevState) => {
          const newState = option.value;
          return newState;
        });
      }
    },
    [setState, isExterior]
  );

  const setIbge = useCallback((ibge) => {
    if (formEditRef.current)
      return formEditRef.current.setFieldValue("address_id_city_ibge", ibge);
  }, []);

  const { loading: loadingEditCep } = useSelector<RootStateOrAny, CepState>(
    (state) => state.cep
  );

  const putDataInFieldsEdit = useCallback(
    (data) => {
      if (!data && formEditRef.current) {
        return formEditRef.current.setFieldError(
          "address_zipcode",
          getTranslation("CEPnaoEncontrado")
        );
      }
      const stateOption = statesOptions.filter(
        (stateOption) => stateOption.value === data.address_state
      );

      if (!isExterior) {
        getListCities(stateOption[0]);
      }

      if (stateOption.length > 0 && formEditRef.current) {
        formEditRef.current.setFieldValue(
          "address_street",
          data.address_street
        );
        formEditRef.current.setFieldValue(
          "address_neighborhood",
          data.address_neighborhood
        );
        formEditRef.current.setFieldValue("address_state", stateOption[0]);
      }
    },
    [getListCities, getTranslation, isExterior]
  );

  const setCityForm = useCallback(
    (cities: ICitiesOptions[]) => {
      const cityOption = cities.filter(
        (city) => city.ibge === codeData.address_id_city_ibge
      );

      if (cityOption.length > 0) {
        setIbge(cityOption[0].ibge);

        if (formEditRef.current) {
          formEditRef.current.setFieldValue("address_city", cityOption[0]);
        }
      }
    },
    [formEditRef, setIbge, codeData]
  );

  useEffect(() => {
    if (cities.length > 0) {
      setCityForm(cities);
    }
  }, [cities, setCityForm]);

  const fetchCep = useCallback(
    (cep) => {
      if (!isExterior) {
        formEditRef.current?.setFieldError("address_zipcode", "");
        const cleanCEP = cep ? cep.replace(/[^0-9]/g, "") : null;
        if (cleanCEP && cleanCEP.length < 8) {
          return formEditRef.current?.setFieldError(
            "address_zipcode",
            getTranslation("CEPInvalido")
          );
        }
        if (cleanCEP && cleanCEP.length === 8) {
          dispatch(CepActions.request(cleanCEP, putDataInFieldsEdit));
        }
      }
    },
    [dispatch, getTranslation, putDataInFieldsEdit, isExterior]
  );

  useEffect(() => {
    if (codeData) {
      formEditRef.current?.setData(codeData);
      if (codeData.address_state && !isExterior) {
        putDataInFieldsEdit(codeData);
      }
    }
  }, [isExterior]);

  return (
    <>
      <Modal isOpen={isOpen}>
        <S.ModalContainer>
          <S.Header>
            {/* <S.IconFilter /> */}
            <S.ModalTitle>
              {getTranslation("codigoSap")}: #{codeData.client_code}
            </S.ModalTitle>
          </S.Header>

          <Form
            ref={formEditRef}
            onSubmit={handleSubmit}
            id="form-edit-client-code"
          >
            <Input
              name="address_id_city_ibge"
              hidden
              containerStyle={{ position: "absolute" }}
            />
            <S.FormRow>
              <InputMask
                name="address_zipcode"
                label={
                  isExterior
                    ? getTranslation("postalCode")
                    : getTranslation("cep")
                }
                onBlur={(e: any) => fetchCep(e.target.value)}
                isLoading={loadingEditCep}
                mask={isExterior ? "********" : "99999-999"}
                // disabled={isExterior}
              />
              <Input
                name="address_street"
                label={getTranslation("lougradouro")}
                // disabled={isExterior}
              />
              <Input
                name="address_number"
                label={getTranslation("numero")}
                // disabled={isExterior}
              />
              <Input
                name="address_neighborhood"
                label={getTranslation("bairro")}
                // disabled={isExterior}
              />
            </S.FormRow>
            <S.FormRow>
              {isExterior ? (
                <Input
                  name="address_state"
                  label={getTranslation("provincy")}
                  // disabled={isExterior}
                />
              ) : (
                <Select
                  name="address_state"
                  label={getTranslation("uf")}
                  options={statesOptions}
                  disabled={isExterior}
                  placeholder={getTranslation("selecione")}
                  onChange={(e) => getListCities(e)}
                />
              )}
              {isExterior ? (
                <Input
                  name="address_city"
                  label={getTranslation("cidade")}
                  // disabled={isExterior}
                />
              ) : (
                <Select
                  name="address_city"
                  label={getTranslation("cidade")}
                  // isDisabled={isExterior ? isExterior : loadingEditCities}
                  // isLoading={loadingEditCities}
                  options={cities}
                  placeholder={getTranslation("selecione")}
                  onChange={(e: any) => setIbge(e.ibge)}
                />
              )}
            </S.FormRow>
          </Form>

          <S.ButtonsWrapper>
            <S.Button
              btStyle="cancel"
              onClick={() => setIsOpen(false)}
              type="button"
            >
              {getTranslation("fechar")}
            </S.Button>
            <S.Button
              onClick={(e) => {
                e.preventDefault();
                formEditRef.current?.submitForm();
              }}
            >
              {updateLoading ? <S.Loading /> : getTranslation("salvar")}
            </S.Button>
          </S.ButtonsWrapper>
        </S.ModalContainer>
      </Modal>
      <S.EditButton
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(true);
        }}
      >
        <S.Edit size={22} />
      </S.EditButton>
    </>
  );
};
