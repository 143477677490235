export const translations = {
  campoObrigatorio: {
    pt: "Campo obrigatório",
    en: "Required field",
    es: "Campo obligatorio",
    de: "Pflichtfeld",
  },
  codigoSap: {
    pt: "Código SAP",
    en: "SAP Code",
    es: "Código SAP",
    de: "SAP-Code",
  },
  fechar: {
    pt: 'Fechar',
    en: 'Close',
    de: 'Schließen',
    es: 'Cerrar',
  },
  salvar: {
    pt: 'Salvar',
    en: 'Save',
    de: 'Speichern',
    es: 'Guardar',
  },
  selecione: {
    pt: "Selecione",
    en: "Select",
    es: "Seleccione",
    de: "Wählen",
  },
  cep: {
    pt: "Cep",
    en: "Zip code",
    de: "Postleitzahl",
    es: "Código postal",
  },
  postalCode: {
    pt: "Código postal",
    en: "Zip code",
    de: "Postleitzahl",
    es: "Código postal",
  },
  uf: {
    pt: "UF",
    en: "Federative unit",
    de: "Bundesstaat",
    es: 'Estado/Provincia',
  },
  provincy: {
    pt: "Província",
    en: "State",
    de: "Länder",
    es: "Província",
  },
  lougradouro: {
    pt: "Logradouro",
    en: "Public place",
    de: "Adresse",
    es: 'Direccion',
  },
  numero: {
    pt: "Número",
    en: "Number",
    de: "Hausnummer",
    es: "Número",
  },
  bairro: {
    pt: "Bairro",
    en: "Neighborhood",
    de: "Stadtviertel",
    es: "Barrio",
  },
  cidade: {
    pt: "Cidade",
    en: "City",
    de: "Ort",
    es: "Ciudad",
  },
};